<template>
  <div />
</template>
<script>
export default {
  name: "RecalcLength",
  props: {
    collection: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    selected: null,
  }),
  created(){
    this.selected = this.collection.master.selected;

    if (!this.selected){
      jet.msg({
        text:'Необходимо выбрать запись!',
        color:'warning'
      });
      return;
    }

    this.recalcLength(this.selected.vcroutesId);
  },
  methods:{
    async recalcLength(routeId){
      const opts = {
        type: 'query', 
        query: 'feb1b1ec-48b9-46d4-b59e-93d9ff3f1f09.prepareRecalcRouteLengthWeb',
        params: {
          in_routeID: routeId,
        }
      }

      try{
        let resp = await jet.http.post(opts);
        if (resp.error){
          throw resp.error;
        }
        jet.msg({
          text:`Пересчет расстояния для маршрута № ${this.selected.vcroutesRoutecode} ${this.selected.vcroutesRoutename} выполнен успешно`
        });
      }catch(e){
        jet.msg({
          text:'В процессе пересчета произошла ошибка', 
          color:'warning'
        });
      }
      this.collection.refresh(routeId);
    }
  }
}

</script>